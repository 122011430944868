import classes from "./RetirementAgeWarning.module.css";
import React, { useState, Fragment } from "react";
import Image from "../../assets/images/warning.png";
import InfoModal from "../../components/general/InfoModal";
import { currencyFormatter } from "../../shared/utilities/Formatters";

const RetirementAgeWarning = (props) => {
  const [showModal, setShowModal] = useState("");
  const remedyPeriodStart = new Date("2015-04-01");
  const remedyPeriodEnd = new Date("2022-04-01");
  

  const showWarnings = (props) => {
    // determining whether the warnings panel should be shown (it should be hidden if there are no warnings)
    // conditions that require a warning: retiring from CARE with ERFs (before 60), unauthorised payment charge x 2
    // also will add implementation for CARE later pension (so retiring from CARE before 55)
    if (props.unauthorised_payment_charge_choose_legacy != 0) {
      return true;
    } else if (props.unauthorised_payment_charge_choose_care != 0) {
      return true;
    } else if (props.retirementAge < props.reformedNPA) {
      return true;
    } else if (props.carePaymentLater) {
      return true;
    } else if (new Date(props.dateOfTransfer) >= remedyPeriodStart && new Date(props.dateOfTransfer) < remedyPeriodEnd) {
      return true;
    } else return false;
  };
  const modalHandler = () => {
    setShowModal("");
  };
  const infoMessage = {
    age: (
      <Fragment>
        <h2> Retiring before Reformed NPA </h2>
        <p>
          The earliest age you can claim benefits without any reductions is
          called your normal pension age, or NPA. You have a different NPA for
          different parts of the Scheme, which means you can take your benefits
          at different times, or choose to take some early and have them
          reduced.
        </p>
        <p>
          In this scenario, you have chosen to retire before your Reformed NPA
          of {props.reformedNPA}. This means your benefits would be reduced for
          early payment.
        </p>
      </Fragment>
    ),
    care_age: (
      <Fragment>
        <h2> Retiring before minimum age in Reformed Scheme </h2>
        <p>
          You are not eligible to receive your Reformed Scheme benefits until
          you reach age 55. If you retire at your chosen retirement age of{" "}
          {props.roundedRetirementAge}, you will receive your{" "}
          {props.legacySchemeName} scheme pension, but you will not be able to
          receive your 2015 scheme pension until you reach at least age 55.
        </p>
        <p>
          These figures assume that you choose to take your 2015 scheme pension
          as soon as possible at age 55, but you can choose to start receiving
          your 2015 scheme benefits at any age after age 55. There are early and
          late retirement factors applied accordingly if you choose to receive
          this benefit from before or after reformed NPA of 60 respectively. For
          the purpose of this illustration, your reformed scheme benefits are
          assumed to be deferred until age 55 and benefits are actuarially
          reduced (relative to deferred pension age State Pension Age). However,
          the modeller does not display the pension being paid in instalments
          before and after age 55 for reform and legacy benefits.
        </p>
      </Fragment>
    ),
    option_a_ls: (
      <Fragment>
        <h2>Tax Threshold Exceeded</h2>
        <p>
          The nominal lump sum you would receive if choosing the Legacy scheme
          in the remedy period exceeds the HMRC 25% tax free limit. This means
          you would incur an unauthorised payment tax charge of{" "}
          {currencyFormatter(props.unauthorised_payment_charge_choose_legacy)}.
          The lump sum in the chart below is net of this tax charge.
        </p>
      </Fragment>
    ),
    option_b_ls: (
      <Fragment>
        <h2>Tax Threshold Exceeded</h2>
        <p>
          The nominal lump sum you would receive if choosing the Reformed scheme
          in the remedy period exceeds the HMRC 25% tax free limit. This means
          you would incur an unauthorised payment tax charge of{" "}
          {currencyFormatter(props.unauthorised_payment_charge_choose_care)}.
          The lump sum in the chart below is net of this tax charge.
        </p>
      </Fragment>
    ),
    remedy_period_transfer: (
      <Fragment>
        <h2>Remedy period transfer not included</h2>
        <p>
          Your transfer-in during the Remedy period has not been included in the 
          illustrator output. If your intended retirement date is within 12 months, 
          you can contact SPPA to request an estimate which takes this transfer-in 
          into account. If your intended retirement date is within 12 months, 
          please refer to your Annual Benefit Statement.
        </p>
      </Fragment>
    ),
  };
  return (
    <div
      className={classes.retirement_age_warning}
      style={
        showWarnings(props) == false
          ? {
              width: 0,
              height: 0,
              margin: 0,
              opacity: 0,
              overflow: "hidden",
              padding: 0,
            }
          : {}
      }
    >
      <h2> Warnings (click to learn more)</h2>
      <div className={classes.warnings_container}>
        <div
          className={
            classes.first_warning +
            " " +
            (props.reformedNPA > props.retirementAge & props.retirementAge >= 55 ? classes.visible : "")
          }
        >
          <div className={classes.image_container}>
            <img src={Image} />
            <button onClick={() => setShowModal("age")}>
              2015 Scheme benefits reduced for early payment at chosen
              retirement age
            </button>
          </div>
        </div>

        <div
          className={
            classes.second_warning +
            " " +
            (props.carePaymentLater ? classes.visible : "")
          }
        >
          <div className={classes.image_container}>
            <img src={Image} />
            <button onClick={() => setShowModal("care_age")}>
              2015 Scheme pension will start being paid later than chosen
              retirement age
            </button>
          </div>
        </div>

        <div
          className={
            classes.third_warning +
            (props.unauthorised_payment_charge_choose_legacy != 0
              ? " " + classes.visible
              : "")
          }
        >
          <div className={classes.image_container}>
            <img src={Image} />
            <button onClick={() => setShowModal("option_a_ls")}>
              Lump sum tax threshold exceeded when choosing Legacy option
            </button>
          </div>
        </div>
        <div
          className={
            classes.fourth_warning +
            (props.unauthorised_payment_charge_choose_care != 0
              ? " " + classes.visible
              : "")
          }
        >
          <div className={classes.image_container}>
            <img src={Image} />
            <button onClick={() => setShowModal("option_b_ls")}>
              Lump sum tax threshold exceeded when choosing Reformed option
            </button>
          </div>
        </div>
        <div
          className={
            classes.fifth_warning +
            ((new Date(props.dateOfTransfer) >= remedyPeriodStart && new Date(props.dateOfTransfer) < remedyPeriodEnd)
              ? " " + classes.visible
              : "")
          }
        >
          <div className={classes.image_container}>
            <img src={Image} />
            <button onClick={() => setShowModal("remedy_period_transfer")}>
              Transfer during Remedy period not included in output
            </button>
          </div>
        </div>
      </div>
      {showModal && (
        <InfoModal
          modalHandler={modalHandler}
          infoMessage={infoMessage[showModal]}
        />
      )}
    </div>
  );
};

export default RetirementAgeWarning;
