// Generate retirement age options
export const generateRetirementAgeOptions = (min, max, currentAge) => {
  const options = [];

  if (currentAge === min) {
    options.push({ label: "Immediate retirement", value: min });
    if (Number.isInteger(currentAge)) {
      min += 1;
    }
  }

  min = Math.ceil(min);

  for (let age = min; age <= max; age++) {
    options.push({ label: `${age} years old`, value: age });
  }
  return options;
};
