import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import Input from "../components/yourdetails/inputs/Input";
import { spaAPI } from "../shared/apis/spaAPI";
import { InputDataContext } from "../shared/contexts/InputDataContext";
import PartTimeServiceInput2 from "../components/yourdetails/inputs/PartTimeServiceInput2";
import CalculateButton from "../components/yourdetails/CalculateButton";
import params from "../shared/utilities/global_parameters";
import { generateRetirementAgeOptions } from "../shared/utilities/generateRetAgeOptions";
import InputRetAgeWarning from "../components/yourdetails/inputs/InputRetAgeWarning";
// import SchemePaysInput from "../components/yourdetails/inputs/SchemePaysInput";

import classes from "./2-YourDetails.module.css";
import { retageAPI } from "../shared/apis/retageAPI";

function YourDetailsPage() {
  const { inputsRef, validRef } = useContext(InputDataContext);
  const [inputs, setInputs] = useState(inputsRef.current);
  const [valid, setValid] = useState(validRef.current);
  const [onPage, setOnPage] = useState(false);
  const [show_remedy_warning, set_show_remedy_warning] = useState(false);
  const today = new Date();
  const birthday = new Date(inputs.dateOfBirth);
  const age = today - birthday;
  const ageNextBirthday = Math.floor(age / 31557600000) + 1;
  const retAgeLower = Math.min(66, Math.max(55, ageNextBirthday));
  let earliestDoB = today.setDate(today.getDate() - 1);
  earliestDoB = new Date(earliestDoB).setFullYear( new Date(earliestDoB).getFullYear() - 60 );
  const latestDateLeft = new Date("2015-04-01");
  const initial_mount = useRef(true);
  const [prevSalaryScale, setPrevSalaryScale] = useState(null);
  const [minHeight, setMinHeight] = useState("97vh"); // Default min-height
  const remedyPeriodStart = new Date("2015-04-01");
  const remedyPeriodEnd = new Date("2022-04-01");

  const earliestDJSyear = new Date(inputs.dateOfBirth).getFullYear() + 18;
  const earliestDJS = new Date(earliestDJSyear, new Date(inputs.dateOfBirth).getMonth(), new Date(inputs.dateOfBirth).getDate());
  
  const min_pt_year = new Date(inputs.dateOfJoining).getFullYear();
  const maxRetAge = 60;

  const retAgeTimeoutRef = useRef(null);
  function debounce(func, wait, name_of_fun, timeoutRef) {
    // Enhanced function to allow cancelation
    const debouncedFunction = function (...args) {
      const later = () => {
        clearTimeout(timeoutRef.current);

        func.apply(this, args);
      };

      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(later, wait);
    };

    return debouncedFunction;
  }
  // creating a debounced retirement age API call, as the useeffect with the API call was causing infinite loop issues
  // when any text inputs were being changed
  const debouncedRetAgeAPI = debounce(
    retageAPI,
    150,
    "setError",
    retAgeTimeoutRef
  );

  const loadSalaryScale = async (opt) => {
    let address =
      (window.LOCAL_SETTING ? window.LOCAL_URL : window.HOST_URL) +
      "/api/salary_scale_" +
      opt;
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    try {
      const response = await fetch(address, requestOptions);
      const data = await response.json();
      setPrevSalaryScale(data);
    } catch (error) {
      console.log(error);
    }
  };

  const smoothScroll = (target, duration) => {
    const targetPosition = window.scrollY + target;
    const startPosition = window.scrollY;
    let startTime = null;

    const ease = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    const animation = (currentTime) => {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const run = ease(timeElapsed, startPosition, targetPosition, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) requestAnimationFrame(animation);
    };

    requestAnimationFrame(animation);
  };

  useEffect(() => {
    if (!initial_mount.current) {
      if (inputs.hasPT === "Yes") {
        smoothScroll(200, 1200);
      }
      // else if (inputs.hasSchemePays === 'Yes'){
      //   smoothScroll(200, 1200)
      // }
    } else {
      initial_mount.current = false;
    }
  }, [inputs.hasPT]);

  useEffect(() => {
    if (inputs.legacyScheme == "2006") {
      setInputs({ ...inputs, commutation: 0 });
    }
  }, [inputs.legacyScheme]);

  let latestDoB = "1999-12-31";
  useEffect(() => {
    if (inputs.dateOfJoining) {
      latestDoB = new Date(inputs.dateOfJoining).setFullYear(
        new Date(inputs.dateOfJoining).getFullYear() - 18
      );
    } else {
      latestDoB = "1999-12-31";
    }
  }, [inputs.dateOfJoining]);

  // inputs that affect retirement age - so API would need to be called if any of them change
  // api will be called here if there is a retirement age/retirement date input - otherwise called for slider
  const retirementAgeInputs = [
    inputs.hasTransferredService,
    inputs.dateOfTransfer,
    inputs.transferredServiceLegacyYears,
    inputs.transferredServiceLegacyDays,
    inputs.dateOfBirth,
    inputs.dateOfJoining,
    inputs.hasPT,
    inputs.partTimeService,
    inputs.rank,
    inputs.legacyScheme,
    inputs.transferredService2015,
    valid.transferredService2015,
    inputs.currentAge
  ];

  // dealing with resetting the chosen retirement age if the retirement age inputs are changed
  // we don't want to do this on the initial mount, because then it will reset when you go back to the page from the results
  // we are using a counter to track the initial mount rather than a true/false as there are a couple of rerenders when the page mounts

  const isInitialMount = useRef(0);

  useEffect(() => {
    isInitialMount.current = isInitialMount.current + 1;
  });

  useEffect(() => {
    //calling ret age API
    //this also clears the selected retirement option
    if (isInitialMount.current > 2) {
      // four possible valid combinations of transfer inputs where retirement age input should appear
      // 1. pre-remedy period transfer with service entered
      // 2. post-remedy period transfer with 2015 pension entered
      // 3. remedy period date entered so no transfer detail entered but user can continue
      // 4. no transfer entered
      if (
        inputs.hasTransferredService == "No" ||
        (inputs.hasTransferredService == "Yes" &&
          (valid.transferredService2015 ||
            inputs.transferredServiceLegacyYears !== "xxx" ||
            (new Date(inputs.dateOfTransfer) >= remedyPeriodStart && new Date (inputs.dateOfTransfer) < remedyPeriodEnd)))
      ) {
        debouncedRetAgeAPI(inputs, setInputs, { label: "", value: "" });
        // now that the selected retirement option has been reset, we also want the retirement age to not be valid
        // so that you can't hit calculate with the blank retirement age
        setValid((prevValid) => ({
          ...prevValid,
          retirementAge: false,
        }));
      }
    }
  }, [...retirementAgeInputs]);

  const retirementAgeOptions = generateRetirementAgeOptions(
    inputs.minRetAge,
    maxRetAge,
    inputs.currentAge
  );

  useEffect(
    () =>
      setValid((prev) => ({ ...prev, inReceipt: inputs.inReceipt === "No" })),
    [inputs.inReceipt]
  );

  useEffect(() => {
    setOnPage(true);
  }, []);

  useEffect(() => {
    loadSalaryScale("2015");
  }, []);

  function Autofill() {
    setInputs({
      employer: "Police",
      region: "Scotland",
      legacyScheme: "2006",
      PAR: "No",
      inReceipt: "No",
      hasLeft: "No",
      dateOfBirth: "1980-01-01",
      dateOfJoining: "2000-01-01",
      salaryCurrent: 50000,
      salary2015: 0,
      retirementAge: 58,
      hasPT: "No",
      partTimeService: [],
      salaryIncrease: 3.25,
      commutation: 100,
      commutation1987: 100,
      equalise: "unequal",
      inflation: "real",
      hasTransferredService: "No",
      rank: "Constable",
    });
    setValid({
      ...valid,
      legacyScheme: true,
      PAR: true,
      inReceipt: true,
      dateOfBirth: true,
      dateOfJoining: true,
      dateLeftScheme: true,
      salaryCurrent: true,
      salary2015: true,
      retirementAge: true,
      hasPT: true,
      hasLeft: true,
      hasTransferredService: true,
      rank: true,
    });
  }

  useEffect(() => {
    validRef.current = valid;
  }, [valid]);

  useEffect(() => {
    if (inputs.hasTransferredService === "Yes") {
      setMinHeight("318vh");
    } else if (
      (inputs.hasPT === "Yes" && inputs.partTimeService.length > 0) ||
      inputs.hasPT === "No"
    ) {
      setMinHeight("277vh");
    } else if (valid.inReceipt) {
      setMinHeight("150vh");
    } else {
      setMinHeight("97vh"); // Default state
    }
  }, [inputs, valid]);

  useEffect(() => {
    // Synchronize local state with context ref
    inputsRef.current = { ...inputs };
  }, [inputs, inputsRef]);

  return (
    <div
      id="Your_Details_Page"
      className={classes.yourDetails}
      style={{
        "--mh": minHeight,
      }}
    >
      <h2>Tell us about your career</h2>
      <p>
        Please answer the following questions to allow us to provide you with an
        estimate of your pension benefits.
      </p>

      <Input
        input={"legacyScheme"}
        inputType={"select"}
        options={["2006", "1987"]}
        text={`Which legacy scheme were you a member of prior to 1st April 2015?`}
        help={null}
        inputs={inputs}
        setInputs={setInputs}
        valid={valid}
        setValid={setValid}
        invisible={false}
      />

      <Input
        input={"dateOfBirth"}
        inputType={"date"}
        text={"What is your date of birth?"}
        inputs={inputs}
        setInputs={setInputs}
        valid={valid}
        setValid={setValid}
        min_year={1956}
        max_year={1996}
        help={
          <Fragment>
            <h2>Date of Birth</h2>
            <p>
              This illustrator can only be used by those who have not yet reached
              the 2015 Scheme normal retirement age of 60. If you would like to
              retire later than age 60, please contact the SPPA.
            </p>
          </Fragment>
        }
        minValue={new Date(earliestDoB)}
        maxValue={new Date(latestDoB)}
        minValErrorMsg={`The maximum age for this illustrator is 60. If you would like to retire later than age 60, please contact the SPPA.`}
        maxValErrorMsg={"The DoB date you've entered is too late."}
        invisible={!valid.legacyScheme}
      />

      <Input
        input={"dateOfJoining"}
        inputType={"date"}
        text={`What date did you join the Pension Scheme?`}
        inputs={inputs}
        setInputs={setInputs}
        valid={valid}
        setValid={setValid}
        min_date={earliestDJS}
        min_year={earliestDJSyear}
        max_year={2012}
        help={
          <Fragment>
            <h2>Date Joined Scheme</h2>
            <p>
              If you don't know the exact date when you joined the scheme,
              please provide the approximate date. If you have multiple periods
              of service because you left and then rejoined, please provide the
              earliest joining date, and in the question about part time working
              that will appear below on this page, enter any intervening periods
              during which you were not in service as part time periods with the
              part time proportion being zero.
            </p>
          </Fragment>
        }
        minValue={earliestDJS}
        maxValue={"2012-03-31"}
        minValErrorMsg={"Your date of joining must be at least 18 years after your date of birth."}
        maxValErrorMsg={"To be eligible for McCloud Remedy you must have joined the pension scheme before 1st April 2012."}
        invalidDateErrorMsg2006={"We would expect members to have joined on or after 6 April 2006 to be members of the 2006 scheme. You have told us you are in the 1987 scheme."}
        invalidDateErrorMsg1987={"We would expect members to have joined before 6 April 2006 to be members of the 1987 scheme. You have told us you are in the 2006 scheme."}
        invisible={!(valid.legacyScheme && valid.dateOfBirth)}
      />

      <Input
        input={"inReceipt"}
        inputType={"select"}
        options={["Yes", "No"]}
        text={`Are you currently in receipt of your Police pension, through full or partial retirement?`}
        help={null}
        inputs={inputs}
        setInputs={setInputs}
        valid={valid}
        setValid={setValid}
        invisible={
          !(valid.legacyScheme && valid.dateOfBirth && valid.dateOfJoining)
        }
      />
      {inputs.inReceipt === "Yes" && (
        <div className={classes.input_container}>
          <p>
            As you have already retired or partially retired, your pension
            administrator will be in touch regarding McCloud remedy if you are
            impacted.
          </p>
        </div>
      )}

      <Input
        input={"hasLeft"}
        inputType={"select"}
        options={["Yes", "No"]}
        text={`Have you opted out of the pension scheme or have you left service?`}
        help={null}
        inputs={inputs}
        setInputs={setInputs}
        valid={valid}
        setValid={setValid}
        invisible={
          !(
            valid.legacyScheme &&
            inputs.inReceipt === "No" &&
            valid.dateOfBirth &&
            valid.dateOfJoining
          )
        }
      />

      {inputs.hasLeft === "Yes" && (
        <div className={classes.input_container}>
          <p>
            You cannot use this illustrator if you have already left the scheme
          </p>
        </div>
      )}

      <Input
        input={"salaryCurrent"}
        inputs={inputs}
        setInputs={setInputs}
        valid={valid}
        setValid={setValid}
        inputType={"pretty"}
        label={"Current salary"}
        text={"What is your most recent full-time annual salary before tax?"}
        help={
          <Fragment>
            <h2>Current Salary</h2>
            <p>
              This should be pensionable earnings and should be the Full-Time
              Equivalent amount. For example, if you are a part-time worker,
              this means that if your actual pay for part-time working is
              £15,000 per year and you work 20 hours per week of a 37 hour week,
              the Full-Time Equivalent would be:
            </p>
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;£15,000 / 20 =
              £750.00 x 37 = £27,750
            </p>
            <p>
              Please note that final pensionable pay used in this illustrator is
              based on gross pay at the point of retirement which differs from
              the actual final pensionable pay definitions that will be used at
              retirement.
            </p>
          </Fragment>
        }
        minValue={1}
        maxValue={1000000}
        minValErrorMsg={"The current salary you've entered is too low."}
        maxValErrorMsg={"The current salary you've entered is too high."}
        invisible={
          !(
            valid.legacyScheme &&
            valid.inReceipt &&
            valid.hasLeft &&
            valid.dateOfBirth &&
            valid.dateOfJoining
          )
        }
      />

      <Input
        input={"salary2015"}
        inputType={"dropdown"}
        label={"2015 Salary"}
        options={
          prevSalaryScale
            ? prevSalaryScale.map((item) => ({
                value: item.salary,
                label: item.item_code.replace("�", "£"),
              }))
            : []
        }
        text={
          "Please select your salary as at 1st April 2015 from the dropdown box below. "
        }
        help={
          <Fragment>
            <h2>Salary as at 1st April 2015</h2>
            <p>
              This should be pensionable earnings and should be the Full-Time
              Equivalent amount. You may enter a custom salary amount by
              selecting the "Custom amount" option in the dropdown list - a new
              input box will appear that will allow you to enter your salary as
              at 1st April 2015. If salary as at 1st April 2015 is unknown then
              select "Unknown salary."
            </p>
          </Fragment>
        }
        inputs={inputs}
        setInputs={setInputs}
        valid={valid}
        setValid={setValid}
        allowNoValue={true}
        invisible={
          !(
            valid.legacyScheme &&
            valid.inReceipt &&
            valid.hasLeft &&
            valid.dateOfBirth &&
            valid.dateOfJoining &&
            valid.salaryCurrent
          )
        }
        allowCustomAmount={true}
      />

      <Input
        input={"hasPT"}
        inputs={inputs}
        setInputs={setInputs}
        valid={valid}
        setValid={setValid}
        inputType={"select"}
        options={["Yes", "No"]}
        text={
          "Have you ever worked part time " +
          (min_pt_year < 2014 ? "before" : "since 1st April " + min_pt_year) +
          " or do you have any gaps in service?"
        }
        help={null}
        invisible={
          !(
            valid.legacyScheme &&
            valid.inReceipt &&
            valid.hasLeft &&
            valid.dateOfBirth &&
            valid.dateOfJoining &&
            valid.salaryCurrent &&
            valid.salary2015
          )
        }
      />

      <PartTimeServiceInput2
        set_show_remedy_warning={set_show_remedy_warning}
        left_margin={"10px"}
        min_year={min_pt_year}
        setInputs={setInputs}
        //max_year was using retirement age input - changing to max retirement age for now
        // max_year = {(new Date(inputs.dateOfBirth)).getFullYear() + Number(inputs.retirementAge) + 13}
        max_year={
          new Date(inputs.dateOfBirth).getFullYear() + Number(maxRetAge) + 13
        }
        visible={
          valid.legacyScheme &&
          valid.inReceipt &&
          valid.hasLeft &&
          valid.dateOfBirth &&
          valid.dateOfJoining &&
          valid.salaryCurrent &&
          valid.salary2015 &&
          inputs.hasPT === "Yes"
        }
      />

      <Input
        input={"rank"}
        inputType={"dropdown"}
        label={"Rank"}
        options={[
          "Commander",
          "Chief Constable",
          "Deputy Chief Constable",
          "Assistant Chief Constable",
          "Chief Superintendent",
          "Superintendent",
          "Chief Inspector",
          "Inspector",
          "Sergeant",
          "Constable",
          "Other",
        ]}
        text={"What is your current rank?"}
        help={
          <Fragment>
            <p>
              It is assumed you will remain in your current rank until
              retirement.
            </p>
          </Fragment>
        }
        inputs={inputs}
        setInputs={setInputs}
        valid={valid}
        setValid={setValid}
        invisible={
          !(
            valid.legacyScheme &&
            valid.inReceipt &&
            valid.hasLeft &&
            valid.dateOfBirth &&
            valid.dateOfJoining &&
            valid.salaryCurrent &&
            valid.salary2015 &&
            valid.hasPT &&
            (inputs.hasPT === "No" ||
              (inputs.hasPT === "Yes" && inputs.partTimeService.length > 0))
          )
        }
        allowCustomAmount={false}
      />

      <Input
        input={"hasTransferredService"}
        inputType={"select"}
        options={["Yes", "No"]}
        text={`Do you have any transferred in service?`}
        help={null}
        inputs={inputs}
        setInputs={setInputs}
        valid={valid}
        setValid={setValid}
        invisible={
          !(
            valid.legacyScheme &&
            valid.inReceipt &&
            valid.hasLeft &&
            valid.dateOfBirth &&
            valid.dateOfJoining &&
            valid.salaryCurrent &&
            valid.salary2015 &&
            valid.rank &&
            valid.hasPT
          )
        }
      />

      {inputs.hasTransferredService === "Yes" && (
        <Input
          input={"dateOfTransfer"}
          inputType={"date"}
          text={"When did you transfer service into the pension scheme?"}
          inputs={inputs}
          setInputs={setInputs}
          valid={valid}
          setValid={setValid}
          min_date={new Date(inputs.dateOfJoining)}
          min_year={new Date(inputs.dateOfJoining).getFullYear()}
          max_year={today.getFullYear()}
          help={null}
          invalidTransferPeriodMsg={
            "Your transfer-in during the Remedy period has not been included in the illustrator output. \
          If your intended retirement date is within 12 months, you can contact SPPA to request an estimate which takes this transfer-in into account. \
          If your intended retirement date is within 12 months, please refer to your Annual Benefit Statement."
          }
          minValue={new Date(inputs.dateOfJoining)}
          minValErrorMsg={"Your transfer date cannot be before your date of joining."}
          invisible={
            !(
              valid.legacyScheme &&
              valid.hasLeft &&
              inputs.hasTransferredService === "Yes" &&
              valid.dateOfBirth &&
              valid.dateOfJoining &&
              valid.inReceipt &&
              valid.rank &&
              valid.hasPT
            )
          }
        />
      )}

      {new Date(inputs.dateOfTransfer) < remedyPeriodStart && (
        <Input
          input={"transferredServiceLegacy"}
          inputs={inputs}
          setInputs={setInputs}
          valid={valid}
          setValid={setValid}
          inputType={"service"}
          text={
            "How many years of pension scheme membership did you have at the date of transfer?"
          }
          help={null}
          minValue={0}
          maxValue={45}
          minValErrorMsg={"The current service you've entered is too low."}
          maxValErrorMsg={"The current service you've entered is too high."}
          invisible={!(inputs.hasTransferredService === "Yes" && 
            valid.dateOfTransfer &&
            valid.legacyScheme &&
            valid.hasLeft &&
            inputs.hasTransferredService === "Yes" &&
            valid.dateOfBirth &&
            valid.dateOfJoining &&
            valid.inReceipt &&
            valid.rank &&
            valid.hasPT)}
        />
      )}

      {new Date(inputs.dateOfTransfer) >= remedyPeriodEnd && (
        <Input
          input={"transferredService2015"}
          inputs={inputs}
          setInputs={setInputs}
          inputType={"pretty"}
          valid={valid}
          setValid={setValid}
          label={"Pension amount"}
          text={
            "Please enter your transferred in pension amount as at the date of your transfer."
          }
          invisible={!(inputs.hasTransferredService === "Yes" && 
            valid.dateOfTransfer && 
            valid.legacyScheme &&
            valid.hasLeft &&
            inputs.hasTransferredService === "Yes" &&
            valid.dateOfBirth &&
            valid.dateOfJoining &&
            valid.inReceipt &&
            valid.rank &&
            valid.hasPT)}
        />
      )}

      {inputs.minRetAge > 60 ? (
        <div className={classes.input_container}>
          <p>
            We have calculated that your earliest possible retirement age is
            over 60. The maximum retirement age for this illustrator is 60. If
            you would like to retire later than age 60, please contact the SPPA.
          </p>
        </div>
      ) : (
        <Input
          input={"retirementAge"}
          inputType={"dropdown"}
          label={"Retirement age"}
          options={
            retirementAgeOptions
              ? retirementAgeOptions.map((item) => ({
                  value: item.value,
                  label: item.label,
                }))
              : []
          }
          text={"At what age do you plan to retire?"}
          help={
            <Fragment>
              <h2>Desired Retirement Age</h2>
              <p>
                Choose your desired retirement age from the dropdown, or choose
                the "Immediate retirement" scenario if this is applicable to
                you. You will be able to change this on the results page to view
                the impact of retiring at different ages.
              </p>
              <h3>Retirement Ages</h3>
              <p>
                The illustrator will allow members to vary their retirement age
                in line with scheme regulations and between ages <b>48 – 60</b>{" "}
                only.
              </p>
              <p>
                In different parts of the scheme, the age at which you are
                eligible for benefits could be different.
              </p>
              {inputs.legacyScheme == "2006" && (
                <p>In the 2006 Scheme, the Normal Pension Age is 55.</p>
              )}
              {inputs.legacyScheme == "1987" && (
                <Fragment>
                  <p>
                    If you are a 1987 member, you may be eligible to retire from active service as follows:
                  </p>
                  <ul style={{ textAlign: "left" }}>
                    <li>
                      Normal pension age (NPA) of 55 but can retire earlier based on the following conditions:
                    </li>
                    <li>
                      At any age once you reach 30 years’ service
                    </li>
                    <li>
                      From age 50 once you reach 25 years’ service
                    </li>
                  </ul>
                  <p>The Compulsory Retirement Age is:</p>
                  <ul style={{ textAlign: "left" }}>
                    <li>
                      for a constable, sergeant, inspector or chief inspector, 60 years 
                    </li>
                    <li>
                      for an officer with any higher rank, 65 years.
                    </li>
                  </ul>
                </Fragment>
              )}
              <p>
                In the Reformed scheme, the Normal Pension Age (NPA) is 60. You
                can also retire from 55 subject to benefits being actuarially
                reduced.
              </p>
            </Fragment>
          }
          inputs={inputs}
          setInputs={setInputs}
          valid={valid}
          setValid={setValid}
          invisible={
            !(
              valid.legacyScheme &&
              valid.inReceipt &&
              valid.hasLeft &&
              valid.dateOfBirth &&
              valid.dateOfJoining &&
              valid.salaryCurrent &&
              valid.salary2015 &&
              valid.hasPT &&
              (inputs.hasPT === "No" ||
                (inputs.hasPT === "Yes" &&
                  inputs.partTimeService.length > 0)) &&
              valid.hasTransferredService &&
              (inputs.hasTransferredService === "No" ||
                (inputs.hasTransferredService == "Yes" &&
                  valid.dateOfTransfer &&
                  (valid.transferredService2015 ||
                    inputs.transferredServiceLegacyYears !== "xxx" ||
                    (new Date(inputs.dateOfTransfer) >= remedyPeriodStart && new Date (inputs.dateOfTransfer) < remedyPeriodEnd)))
                  )
            )
          }
          allowCustomAmount={false}
        />
      )}

      {!show_remedy_warning &&
      valid.legacyScheme &&
      valid.inReceipt &&
      valid.hasLeft &&
      valid.dateOfBirth &&
      valid.dateOfJoining &&
      valid.salaryCurrent &&
      valid.salary2015 &&
      valid.retirementAge &&
      valid.hasPT &&
      valid.hasTransferredService &&
      (inputs.hasTransferredService === "No" ||
        (inputs.hasTransferredService == "Yes" &&
          valid.dateOfTransfer &&
          (valid.transferredService2015 ||
            inputs.transferredServiceLegacyYears !== "xxx" ||
            (new Date(inputs.dateOfTransfer) >= remedyPeriodStart && new Date (inputs.dateOfTransfer) < remedyPeriodEnd)))
          ) ? (
        <div className={classes.button_container}>
          <CalculateButton />
        </div>
      ) : null}

      {show_remedy_warning &&
        valid.hasPT &&
        inputs.BenefitStatement === "Yes" && (
          <div className={classes.service_too_low}>
            The minimum service implied by your part-time history is higher than
            the service you have provided above. Please correct your part-time
            history or service.
          </div>
        )}
      
      {/* <br></br>
      <h2>Inputs:</h2>
      <pre>
        {JSON.stringify(inputs, null, 2)}
        <br />
      </pre> */}
    </div>
  );
}

export default YourDetailsPage;
