import classes from "./ResultsTable.module.css";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { InputDataContext } from "../../shared/contexts/InputDataContext";
import { OutputDataContext } from "../../App";
import { formatDate, currencyFormatter } from "../../utilities/Formatters";

const ResultsTable = (props) => {
  const { inputsRef } = useContext(InputDataContext);
  const { outputDataRef } = useContext(OutputDataContext);
  const status = props.status;
  const spa_years = inputsRef.current.spa_age["years"];
  const spa_months = inputsRef.current.spa_age["months"];
  const spa_age = spa_years + spa_months / 12;
  const npa_mapper = {
    "1995 Section": status === "Neither of these" ? 60 : 55,
    "2008 Section": 65,
    2015: Math.max(spa_age, 65),
  };
  const legacy_scheme = inputsRef.current.legacyScheme;
  const legacy_npa = npa_mapper[legacy_scheme];
  const reformed_npa = npa_mapper["2015"];
  const ret_age = inputsRef.current.retirementAge;

  const dummy_number = 100;

  return (
    <div className={classes.results_table}>
      <h1> Simple view</h1>
      <p> Your choice of pension benefits at a glance </p>

      <div className={classes.boxes_container}>
        <div className={classes.box}>
          <h2> Based on </h2>
          <div className={classes.based_on}>
            <div>
              <p> Retirement age: </p> <p> {inputsRef.current.retirementAge}</p>{" "}
            </div>

            <div>
              <p> Salary increase: </p>{" "}
              <p> {outputDataRef.current.salaryIncrease}%</p>{" "}
            </div>
          </div>
        </div>
        <div className={classes.box + " " + classes.choose_option}>
          <p>
            {" "}
            If you choose Option A (Standard Benefits from the {
              legacy_scheme
            }){" "}
          </p>
        </div>
        <div></div>
        <div className={classes.box + " " + classes.choose_option}>
          <p> If you choose Option B (Alternative Benefits)</p>
        </div>
        <div className={classes.box}>
          <h2> you will receive a pension of: </h2>
          <h1>
            {" "}
            {currencyFormatter(
              outputDataRef.current.pension.choose_legacy.new +
                outputDataRef.current.pension.choose_legacy.old
            )}
          </h1>
        </div>
        <div></div>
        <div className={classes.box}>
          <h2> you will receive a pension of: </h2>
          <h1>
            {" "}
            {currencyFormatter(
              outputDataRef.current.pension.choose_reformed.new +
                outputDataRef.current.pension.choose_reformed.old
            )}
          </h1>
        </div>
        <div className={classes.box}>
          <h2> and a lump sum of: </h2>
          <h1>
            {" "}
            {currencyFormatter(outputDataRef.current["lump sum"].choose_legacy)}
          </h1>
        </div>
        <div></div>
        <div className={classes.box}>
          <h2> and a lump sum of: </h2>
          <h1>
            {" "}
            {currencyFormatter(
              outputDataRef.current["lump sum"].choose_reformed
            )}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default ResultsTable;
