import React, { Fragment, useState, useRef, useEffect } from "react";
import InfoModal from "../../general/InfoModal";
import { submitInputData } from "../../../shared/apis/mainAPI";
import classes from "./ControlSwitches.module.css";
import { generateRetirementAgeOptions } from "../../../shared/utilities/generateRetAgeOptions";

const RetirementAgeSwtich = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [retirementAge, setRetirementAge] = useState(
    props.inputsRef.current.retirementAge
  );
  const [legacy_warning, set_legacy_warning] = useState("");
  const [reformed_warning, set_reformed_warning] = useState("");
  const [text_focused, set_text_focused] = useState(false);
  const exact_age_rounded = props.userExactAgeRoundedDown;
  const exact_age = props.userExactAge;

  const retirementAgeOptions = generateRetirementAgeOptions(
    props.inputsRef.current.minRetAge,
    props.inputsRef.current.maxRetAge,
    props.inputsRef.current.currentAge
  );
  const disableRetAgeSwitch = retirementAgeOptions.length == 1;
  const [retAgeWarning, setRetAgeWarning] = useState("");

  const findNextRetirementAgeOption = (currentValue, direction) => {
    const currentIndex = retirementAgeOptions.findIndex(
      (option) => option.value === currentValue
    );
    if (currentIndex === -1) return currentValue;
    const newIndex = currentIndex + direction;
    if (newIndex < 0 || newIndex >= retirementAgeOptions.length) {
      return retirementAgeOptions[currentIndex]; // If the new index is out of bounds, return the current option
    }
    return retirementAgeOptions[newIndex];
  };

  var extra = null;
  var extra2 = "";

  if (Number(retirementAge.value) === Number(exact_age)) {
    if (Number(exact_age) > Number(exact_age)) {
      var extra = (
        <p className={classes.immediate_ret}>
          {" "}
          *This means retiring immediately today
        </p>
      );
      var extra2 = "*";
    }
  }
  const initial_mount = useRef(true);

  function clean_retirement_string(str) {
    return str.replace(/[^0-9]/g, "");
  }

  const legacy_npa = props.legacy_npa;
  // legacy npa returned by backend is 55 in pol 1987 and pol 2006 schemes, but 1987 scheme is more complicated as technically the NPA
  // is when they hit 30 years of service. so this is called "legacy NPA" in the code, but we are referring to "age 55" in the button.
  const reformed_npa = props.reformed_npa;

  function changeHandler(event) {
    let cleaned = clean_retirement_string(event.target.value);
    cleaned = Number(cleaned);
    cleaned = Math.min(60, cleaned);
    cleaned = Math.max(0, cleaned);
    const newOption =
      retirementAgeOptions.find((option) => option.value === cleaned) ||
      retirementAgeOptions[0];
    setRetirementAge({ value: cleaned, label: `${cleaned}` });
  }

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (!initial_mount.current && !text_focused) {
      // Define an async function inside useEffect
      const fetchData = async () => {
        props.inputsRef.current["retirementAge"] = retirementAge;
        try {
          const data = await submitInputData({
            ...props.inputsRef.current,
            retirementAge: retirementAge,
          });
          props.setOutputData(data);
        } catch (error) {
          console.error("Error fetching data:", error);
          // Handle any errors here
        }
      };

      // Call the async function
      fetchData();
    } else {
      initial_mount.current = false;
    }
  }, [retirementAge, text_focused]);

  const infoMessage = (
    <Fragment>
      <h2>Adjust Retirement Age</h2>
      <p>
        You can use these buttons below to provide a simple illustration of the
        annual pension and lump sum that you may receive in scenarios relevant
        to you.
      </p>
    </Fragment>
  );

  return (
    <div className={classes.main_container}>
      {showModal && (
        <InfoModal modalHandler={modalHandler} infoMessage={infoMessage} />
      )}
      <header className={classes.header}>
        <h3>Adjust Retirement Age</h3>
        <button
          id="RetirementAgeSwitchHelp"
          className={classes.help_button}
          onClick={modalHandler}
        >
          Help
        </button>
      </header>

      <div
        className={
          classes.radio_container + " " + classes.extra_container + " "
        }
        onMouseOver={() => {
          if (disableRetAgeSwitch) {
            setRetAgeWarning(
              "The maximum age for this illustrator is 60. If you would like to retire later than age 60, please contact the SPPA."
            );
          }
        }}
        onMouseLeave={() => {
          setRetAgeWarning("");
        }}
      >
        <button
          onClick={() =>
            setRetirementAge((prev) =>
              findNextRetirementAgeOption(prev.value, -1)
            )
          }
          className={
            classes.adjust_button +
            " " +
            (disableRetAgeSwitch ? classes.greyed_out : "")
          }
        >
          {" "}
          -{" "}
        </button>

        <input
          type="text"
          disabled={disableRetAgeSwitch}
          value={
            (text_focused ? retirementAge.value : retirementAge.label) + extra2
          }
          onChange={changeHandler}
          onFocus={() => set_text_focused(true)}
          onBlur={() => {
            set_text_focused(false);
            const value = parseFloat(retirementAge.value);
            const closestOption = retirementAgeOptions.reduce((prev, curr) =>
              Math.abs(curr.value - value) < Math.abs(prev.value - value)
                ? curr
                : prev
            );
            setRetirementAge({
              value: closestOption.value,
              label: Number.isInteger(closestOption.value)
                ? `${closestOption.value} years old`
                : "Immediate retirement",
            });
          }}
        />
        {extra}
        <button
          onClick={() =>
            setRetirementAge((prev) =>
              findNextRetirementAgeOption(prev.value, 1)
            )
          }
          className={
            classes.adjust_button +
            " " +
            (disableRetAgeSwitch ? classes.greyed_out : "")
          }
        >
          {" "}
          +{" "}
        </button>
        {retAgeWarning !== "" && (
          <p className={classes.warning_message} s>
            {" "}
            {retAgeWarning}
          </p>
        )}
      </div>
      {/* <div className = {classes.npa_buttons_container}> 
        <button 
        className = {
          classes.adjust_button + ' ' +
           (exact_age > legacy_npa || props.inputsRef.current.minRetAge > legacy_npa ? classes.greyed_out : '') + ' ' +
           (legacy_npa === retirementAge.value ? classes.active_button : '')
        }
        onClick = {() =>{
          if(legacy_npa >= exact_age) {
            setRetirementAge(
              {value: legacy_npa,
              label: `${legacy_npa} years old`}
            )
          } 
        }
        }
        onMouseOver = {
          () => {
            if(exact_age > legacy_npa) {
              set_legacy_warning("You can't set your retirement age to 55 because you are already older than 55.")
            } else if(props.inputsRef.current.minRetAge > legacy_npa) {
              set_legacy_warning("You can't set your retirement age to 55 because you are not eligible to retire at 55 based on service.")
            } 
          }
        }
        onMouseLeave = {
          () => {set_legacy_warning('')}
        }
        > set to age 55 </button>
        
        <button 
        className = {classes.adjust_button + ' ' + (exact_age > reformed_npa ? classes.greyed_out : '') + ' ' +
        (reformed_npa === retirementAge.value ? classes.active_button : '')}
        onClick = {() =>{
          if(reformed_npa >= exact_age) {
            setRetirementAge(
              {value: reformed_npa,
              label: `${reformed_npa} years old`}
            )
          } 
        }
        }
        onMouseOver = {
          () => {
            if(exact_age > reformed_npa) {
              set_reformed_warning("You can't set your retirement age to your reformed NPA because you are already older than your reformed NPA.")
            }
          }
        }
        onMouseLeave = {
          () => {set_reformed_warning('')}
        }
        > 
        set to reformed NPA 
        </button>
         </div> */}
      {legacy_warning !== "" && (
        <p className={classes.warning_message}> {legacy_warning}</p>
      )}
      {reformed_warning !== "" && (
        <p className={classes.warning_message} s>
          {" "}
          {reformed_warning}
        </p>
      )}
    </div>
  );
};

export default RetirementAgeSwtich;
