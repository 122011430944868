import React, { useState, useEffect, useRef } from 'react';
import classes from './FulDateDropdown.module.css'
import DateDropdown from './DateDropdown';

const FullDateDropdown = (props) => {
    
  const initial_mount = useRef(true)
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Set the minimum possible day, month and year based on supplied minimum date
  let min_day   = 1;
  let min_month = 0;
  let min_year  = props.min_year;
  if (props.min_date !== undefined) {
    min_day   = new Date(props.min_date).getDate();
    min_month = new Date(props.min_date).getMonth();
    min_year  = new Date(props.min_date).getFullYear();
  }
  
  // Function to identify whether year is a leap year
  function isLeapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }
  
  // Function to identify the maximum day of the selected month and year
  function get_max_possible_day(year, month) {
    if (month === "February") {
      return isLeapYear(year) ? 29 : 28;
    } else if (["April", "June", "September", "November"].includes(month)) {
      return 30;
    } else {
      return 31;
    }
  }
  
  // Function to create the list of days based on month and year - this should take into account a minimum date limit
  function setDays(m, y) {
    var month_index = months.indexOf(m)
    let days = [];
    let min_d = (((y === min_year) && (month_index === min_month)) ? min_day : 1);
    let max_d = get_max_possible_day(y, m);
    for (let d = min_d; d <= max_d; d++) { days.push(d); }
    return days;
  }

  // Set initial values
  if(props.existing_value !== '') {
      const ev           = props.existing_value
      var existing_year  = ev.slice(0,4)
      var existing_month = ev.slice(5,7)
      existing_month     = months[Number(existing_month) - 1]
      var existing_day   = ev.slice(8,10)
      existing_day       = Number(existing_day)
  } else {
      var existing_year  = 'Year'
      var existing_month = 'Month'
      var existing_day   = 'Day'
  }
  const [year, set_year]   = useState(existing_year)
  const [month, set_month] = useState(existing_month)
  const [day, set_day]     = useState(existing_day)
  useEffect(
    () => {
      if(props.existing_value === '' && !initial_mount.current) {
        set_year('Year')
        set_month('Month')
        set_day('Day')
      }
      initial_mount.current = false
    },
    [props.existing_value]
  )

  // Update dropdown options
  const dayRef = useRef(day)
  const monthRef = useRef(month)
  useEffect(
    () => {
      // Ensure leading zeroes for days and months
      if((year !== 'Year') && (month !== 'Month') && (day !== 'Day') && ((String(year) + '-' + String(month) + '-' + String(day)) !== props.existing_value)) {
        if(String(day).length === 1) { var formatted_day = '0' + day } else { var formatted_day = day }
        var formatted_month = months.indexOf(month) + 1
        if(String(formatted_month).length === 1) { formatted_month = '0' + formatted_month }
        props.onChange( {'target' : {'value' : String(year) + '-' + String(formatted_month) + '-' + String(formatted_day)}} )
      }
      
      // if day selection changes, cap value at maximum possible day of the month
      if(dayRef.current === day) {
        const min_possible_day = ((year === min_year && months.indexOf(month) === min_month) ? min_day : 1)
        const max_possible_day = get_max_possible_day(year, month)
        if(min_possible_day > day) { set_day(min_possible_day) }
        if(max_possible_day < day) { set_day(max_possible_day) }
      }

      // set minimum possible month
      if(monthRef.current === month && (month !== 'Month')) {
        const min_possible_month = (year === min_year ? min_month : 0)
        if(min_possible_month > months.indexOf(month)) { set_month(months[min_possible_month]) }
      }

      dayRef.current = day
      monthRef.current = month
    }, 
    [day, month, year]
  )
  
  // Set the days array based on the user's choice
  let days = setDays(month, year);

  // Generate list of years
  let years = [];
  for (let y = min_year; y <= props.max_year; y++) { years.push(y); }

  
  return (
    <div className = {classes.dropdowns_container}> 
      <DateDropdown
        options = { days }
        value = {day}
        set_value = {set_day}
        name = {'day'}
        scrollPositions = {props.scrollPositions}
        go_red = {props.go_red}
      />
      <DateDropdown
        options = { months.slice( props.min_year === year ? min_month : 0, 12) } 
        value = {month}
        set_value = {
          (x) => {
            var month_index = months.indexOf(x)
            if(year === props.min_year) { month_index = Math.max(min_month, month_index) }
            set_month(months[month_index])
          }
        }
        name = {'month'}
        scrollPositions = {props.scrollPositions}
        go_red = {props.go_red}
      />
      <DateDropdown
        options = {years}
        value = {year}
        set_value = {set_year}
        name = {'year'}
        scrollPositions = {props.scrollPositions}
        go_red = {props.go_red}
      />
    
    {/* <br></br>
    <pre>
      {min_day}, {min_month}, {min_year}
      <br />
      {day}, {month}, {year}
    </pre> */}

    </div>
    )
}

export default FullDateDropdown