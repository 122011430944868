import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import classes from "./DownloadButton.module.css";
import {
  currencyFormatter,
  sumValues,
  formatDate,
} from "../../../utilities/Formatters";

const DownloadPdfButton = (props) => {
  const inflationRelevant =
    props.outputData.exact_age < props.outputData.retirementAge ||
    (props.outputData.exact_age >= props.outputData.retirementAge &&
      props.outputData.retirementAge < 55);

  const cfl = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  const generateTimestamp = () => {
    const now = new Date();
    return now.toLocaleString("en-GB");
  };

  const generatePdf = () => {
    const containsAsterisk = props.saved_scenarios_ref.current.some(
      (scenario) =>
        scenario.classic["Retirement Age"].includes("*") ||
        scenario.alpha["Retirement Age"].includes("*")
    );

    const includePensionNotice = containsAsterisk || props.carePaymentLater;

    const doc = new jsPDF();

    const timestamp = generateTimestamp();
    const pageWidth = doc.internal.pageSize.getWidth();
    let y = 20; // Initialize Y position

    // Set up table header colour (blue)
    const tableHeadColor = [0, 51, 153];

    // === PAGE 1: INTRO & INPUT DATA (Portrait) ===
    doc.setFont("helvetica");
    doc.setFontSize(15);

    // Centered Title
    const title = "Your Remedy Benefit Illustrator Inputs";
    doc.text(title, (pageWidth - doc.getTextWidth(title)) / 2, y);
    y += 10;

    // Timestamp & Page Number
    doc.setFontSize(9);
    doc.text(`Generated on: ${timestamp}`, 10, y);
    doc.text("Page 1 of 2", pageWidth - 30, y);
    y += 10;

    // User Inputs Summary
    doc.setFontSize(11);
    doc.text("Your input data is summarised below.", 10, y);
    y += 5;
    doc.autoTable({
      head: [
        [
          "Date of Birth",
          "Joining Date",
          "Rank",
          "Current Salary",
          "Salary in April 2015",
        ],
      ],
      body: [
        [
          formatDate(props.inputsRef.current.dateOfBirth),
          formatDate(props.inputsRef.current.dateOfJoining),
          props.inputsRef.current.rank.value,
          currencyFormatter(props.inputsRef.current.salaryCurrent),
          currencyFormatter(props.inputsRef.current.salary2015.value),
        ],
      ],
      startY: y,
      styles: { fontSize: 8 },
      theme: "grid",
      headStyles: { fillColor: tableHeadColor, textColor: [255, 255, 255] },
    });
    y = doc.autoTable.previous.finalY + 10;

    // Part-Time Service
    const partTimeData = props.inputsRef.current.partTimeService.map((x) => [
      formatDate(x["From"]),
      formatDate(x["To"]),
      x["ftePercentage"] + "%",
    ]);

    if (partTimeData.length > 0 && props.inputsRef.current.hasPT === "Yes") {
      doc.text(
        "You entered the following data regarding your part-time service:",
        10,
        y
      );
      y += 5;
      doc.autoTable({
        head: [["From", "To", "Percentage"]],
        body: partTimeData,
        startY: y,
        styles: { fontSize: 8 },
        theme: "grid",
        headStyles: { fillColor: tableHeadColor, textColor: [255, 255, 255] },
      });
      y = doc.autoTable.previous.finalY + 10;
    } else {
      doc.text("You did not enter any part-time service.", 10, y);
      y += 10;
    }

    // Transferred Service
    if (
      "hasTransferredService" in props.inputsRef.current &&
      props.inputsRef.current.hasTransferredService === "Yes"
    ) {
      const transferDate = new Date(props.inputsRef.current.dateOfTransfer);
      const remedyPeriodStart = new Date("2015-04-01");
      const remedyPeriodEnd = new Date("2022-04-01");

      if (transferDate >= remedyPeriodStart && transferDate < remedyPeriodEnd) {
        const transferWarning = "As you entered a transfer date during the Remedy period, your transfer-in has not been included in the illustrator output."
        doc.text(doc.splitTextToSize(transferWarning, 180), 10, y);
        y += 15;
      } else {
        const tableBody = [];

        doc.text(
          "You entered the following data regarding your transferred service:",
          10,
          y
        );
        y += 5;

        // Date of Transfer should always be included
        tableBody.push([
          "Date of Transfer",
          formatDate(props.inputsRef.current.dateOfTransfer),
        ]);

        // Include service years & days for transfers before or during 2015
        if (transferDate < remedyPeriodStart) {
          tableBody.push(
            [
              "Transferred Service (Years)",
              props.inputsRef.current.transferredServiceLegacyYears,
            ],
            [
              "Transferred Service (Days)",
              props.inputsRef.current.transferredServiceLegacyDays,
            ]
          );
        }

        // Include transferred pension for transfers in or after 2022
        if (transferDate >= remedyPeriodEnd) {
          tableBody.push([
            "Transferred Pension",
            currencyFormatter(props.inputsRef.current.transferredService2015),
          ]);
        }

        doc.autoTable({
          head: [["Transfer Details", "Value"]],
          body: tableBody,
          startY: y,
          styles: { fontSize: 8 },
          theme: "grid",
          headStyles: { fillColor: tableHeadColor, textColor: [255, 255, 255] },
        });

        y = doc.autoTable.previous.finalY + 10;
      }
    } else {
      doc.text("You did not enter any transferred service.", 10, y);
      y += 10;
    }

    // Link to Next Page
    doc.setFontSize(11);
    doc.text(
      "The next page contains your comparison table of estimated pension benefits in the specified scenarios.",
      10,
      y
    );

    // === PAGE 2: RESULTS TABLE (Landscape) ===
    doc.addPage("a4", "landscape");

    const landscapeWidth = doc.internal.pageSize.getWidth();
    y = 20; // Reset Y position

    doc.setFontSize(15);
    doc.text(
      "Your Remedy Benefits Illustrator Results",
      (landscapeWidth - doc.getTextWidth("Your Pension Comparison Table")) / 2,
      y
    );
    y += 10;

    // Timestamp & Page Number
    doc.setFontSize(9);
    doc.text(`Generated on: ${timestamp}`, 10, y);
    doc.text("Page 2 of 2", landscapeWidth - 30, y);
    y += 10;

    // Selected scanrios
    doc.setFontSize(11);
    doc.text(
      "The projected values in your chosen retirement scenarios are summarised below.",
      10,
      y
    );
    y += 5;

    // Define Table Columns (Prevents Mid-Word Splitting)
    const tableColumn = [
      "Retirement Age",
      "McCloud Choice",
      "Annual Salary Growth",
      "Inflation",
      ...(props.legacySchemeName === "1987"
        ? [props.legacySchemeName + " Commutation"]
        : []),
      "2015 Commutation",
      props.legacySchemeName + " Pension",
      props.remedySchemeName + " Pension",
      "Total Pension",
      props.legacySchemeName + " Lump Sum",
      props.remedySchemeName + " Lump Sum",
      "Total Lump Sum",
    ];

    let tableRows = [];

    const createRow = (option, pensionData, lumpSumData) => [
      props.roundedRetirementAge + (props.carePaymentLater ? "*" : ""),
      option,
      props.outputData.salaryIncrease + "%",
      inflationRelevant ? cfl(props.outputData.inflation) : "N/A",
      ...(props.legacySchemeName === "1987"
        ? [props.outputData.commutation1987 + "%"]
        : []),
      props.outputData.equalise === "equal"
        ? "Equalised"
        : props.outputData.commutation + "%",
      currencyFormatter(pensionData.old),
      currencyFormatter(
        props.carePaymentLater ? pensionData.new_later : pensionData.new
      ),
      props.carePaymentLater
        ? "N/A*"
        : currencyFormatter(
            sumValues([
              pensionData.old,
              props.carePaymentLater ? pensionData.new_later : pensionData.new,
            ])
          ),
      currencyFormatter(lumpSumData.old),
      currencyFormatter(lumpSumData.new),
      props.carePaymentLater
        ? "N/A*"
        : currencyFormatter(sumValues([lumpSumData.old, lumpSumData.new])),
    ];

    tableRows.push(
      createRow(
        "Legacy",
        props.pension.choose_legacy,
        props.lump_sum.choose_legacy
      )
    );
    tableRows.push(
      createRow(
        "Reformed",
        props.pension.choose_reformed,
        props.lump_sum.choose_reformed
      )
    );

    props.saved_scenarios_ref.current.forEach((scenario) => {
      const { classic, alpha } = scenario;

      const safeFormat = (value) =>
        typeof value === "string" ? value : currencyFormatter(value);

      const classicRow = [
        classic["Retirement Age"],
        "Legacy",
        classic["Salary Increase"],
        classic["Inflation"],
        ...(props.legacySchemeName === "1987"
          ? [classic[props.legacySchemeName + " Commutation"]]
          : []),
        classic["2015 Commutation"],
        safeFormat(classic[props.legacySchemeName + " pension"]),
        safeFormat(classic[props.remedySchemeName + " pension"]),
        safeFormat(classic["Total Pension"]),
        safeFormat(classic[props.legacySchemeName + " lump sum"]),
        safeFormat(classic[props.remedySchemeName + " lump sum"]),
        safeFormat(classic["Lump Sum"]),
      ];

      const alphaRow = [
        alpha["Retirement Age"],
        "Reformed",
        alpha["Salary Increase"],
        alpha["Inflation"],
        ...(props.legacySchemeName === "1987"
          ? [alpha[props.legacySchemeName + " Commutation"]]
          : []),
        alpha["2015 Commutation"],
        safeFormat(alpha[props.legacySchemeName + " pension"]),
        safeFormat(alpha[props.remedySchemeName + " pension"]),
        safeFormat(alpha["Total Pension"]),
        safeFormat(alpha[props.legacySchemeName + " lump sum"]),
        safeFormat(alpha[props.remedySchemeName + " lump sum"]),
        safeFormat(alpha["Lump Sum"]),
      ];
      tableRows.push(classicRow, alphaRow);
    });
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: y,
      styles: { fontSize: 8 },
      theme: "grid",
      headStyles: { fillColor: tableHeadColor, textColor: [255, 255, 255] },
    });
    y = doc.autoTable.previous.finalY + 10;

    // Pension notice
    const pensionNotice =
      "* Please be aware, in this scenario you have chosen to retire before age 55 (in line with your 1987 benefit entitlement). " +
      "However, you are not eligible to receive your reformed scheme benefits until you reach age 55. " +
      "For the purpose of this illustration, your reformed scheme benefits are assumed to be deferred until age 55 and benefits are " +
      "actuarially reduced (relative to deferred pension age SPA). However, the modeller does not display the pension being paid in " +
      "instalments before and after age 55 for reform and legacy benefits.";

    // Disclainer
    const disclaimer =
      "The figures in this document are based on the input data provided. Without the corresponding inputs detailed on Page 1, " +
      "these calculations and projections should not be used in isolation. The assumptions made in generating this report " +
      "must be taken into consideration when interpreting the results.";

    doc.setFontSize(9);
    if (includePensionNotice) {
      doc.text(doc.splitTextToSize(pensionNotice, 270), 10, y);
      y += 15;
    }
    doc.text(doc.splitTextToSize(disclaimer, 270), 10, y);

    // Save PDF
    doc.save(`your-data-${timestamp}.pdf`);
  };

  return (
    <div className={classes.download_button_container}>
      <button className={classes.download_button} onClick={generatePdf}>
        Download PDF
      </button>
    </div>
  );
};

export default DownloadPdfButton;
