import React, { Fragment, useState, useEffect, useContext, useRef } from "react";
import classes from './PrettyTextInput.module.css'

const PrettyTextInput = (props) => {

  
	function formatToPoundNoRounding(number) {
    if(String(number).endsWith('.')) {
      var suffix = '.'
    } else {
      if(String(number).endsWith('.0')) {
        var suffix = '.0'
      } else {
        var suffix = ''
      }
    }


		const ret = new Intl.NumberFormat('en-GB', {
			style: 'currency',
			currency: 'GBP',
			minimumFractionDigits: 0
		}).format(number);

    //xxx signals that the user has not supplied an input yet
    if(String(number) === 'xxx') {
      return('')
    }
		if(isNaN(number) || String(number) === '£0' || String(number) === '' || String(number) === '0') {
			return '£0'
		} else {
			return ret + suffix
		}
	}

  function clean_starting_zero(number) {
    if(String(number).startsWith('0') && String(number).length > 1) {
      return number.slice(1)
    } else {
      return(number)
    }
  }


  const [focused, set_focused] = useState(false)

  const border_color = (
      (props.showError && props.error) ? 'red' :
      (props.value === '' ? 'var(--border-hovered)' :  'var(--background-selected)')
      )

  const text_color = (((props.value !== '') | props.showError | focused))? border_color : 'black'
  return(
      <div className={classes.mahi_holder}
      style = {
        props.left_margin ? {'--left-margin' : props.left_margin}: {}
      }
      >

              
              <div className={classes['col-3'] + ' ' + classes['input-effect']}
              style = {{
                  '--top-margin' : ((focused | (props.value !== ''))? '0' :'-3.5px'), 
                  '--side-margin' : ((focused | (props.value !== '' )) ? '0' : '-4px'), 
                  '--border-color' : border_color, 
                  '--text-color' : text_color

              }}
              
              >
                  <input 
                  className={classes["effect-19"] +  ' ' + (props.value !== '' ? classes['has-content'] : '')}
                  type= {props.type === 'currency' ? 'text' : 'number'}
                    placeholder=""
                    onChange = {props.onChange}
                    value = {props.type === 'currency' ? formatToPoundNoRounding(props.value || '') : (props.value === 0 ? '0' :(clean_starting_zero(props.value) || ''))}
                    onFocus = { () => set_focused(true) }
                    onBlur = { () => set_focused(false) }
                    />
                  <label>{props.label}</label>
                  <span className= {classes["focus-border"]}>
                      <i></i>
                  </span>
              </div>
          

      </div>
      )
}

export default PrettyTextInput

