export const submitInputData = (inputs) => {
  // API endpoint
  let address =
  (window.LOCAL_SETTING ? window.LOCAL_URL : window.HOST_URL) + "/api/setup";

  const service_days = inputs.transferredServiceLegacyDays === "xxx" ? 0 : (inputs.transferredServiceLegacyDays === '' ? 0 : inputs.transferredServiceLegacyDays)
  const service_years = inputs.transferredServiceLegacyYears === "xxx" ? 0 : (inputs.transferredServiceLegacyYears === '' ? 0 : inputs.transferredServiceLegacyYears)
  const transferred_pension = inputs.transferredService2015 === "xxx" ? 0 : (inputs.transferredService2015 === '' ? 0 : inputs.transferredService2015)

  const transferred_service_legacy = (inputs.hasTransferredService === "Yes" && new Date(inputs.dateOfTransfer) < new Date("2015-04-01")) ? (service_years + service_days/365) : 0

  const InputData = {
    employer: inputs.employer,
    region: inputs.region,
    dateJoinedScheme: inputs.dateOfJoining,
    dateOfBirth: inputs.dateOfBirth,
    if_left: inputs.hasLeft,
    hasPT: inputs.hasPT,
    partTimes: inputs.partTimeService,
    scheme: inputs.legacyScheme,
    retirementAge: inputs.retirementAge.value,
    salary_today: inputs.salaryCurrent,
    salary_2015: inputs.salary2015.value,
    salary_increase: inputs.salaryIncrease / 100,
    hasTransferredService: inputs.hasTransferredService,
    transferDate: inputs.dateOfTransfer,
    transferredInService : transferred_service_legacy,
    transferredInPension : transferred_pension,
    rankAtRetirement : inputs.rank.value,
    equalise_lump_sum : inputs.equalise,
    commutation2015 : inputs.commutation, 
    commutation1987 : inputs.commutation1987,
    inflation : inputs.inflation, 
  };

  // Request options for fetch
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(InputData),
  };

  // Perform the API request
  return fetch(address, requestOptions)
    .then(response => response.json())
    .then(from_backend => {
      const ret = {
        'pension' :   {
          'choose_legacy' : {
            'old' : from_backend['LP choose legacy'], 
            'new' : from_backend['CP choose legacy'],
            'new_later' : from_backend['CP choose legacy later payment']
          } ,
          'choose_reformed' : {
            'old' : from_backend['LP choose care'], 
            'new' : from_backend['CP choose care'],
            'new_later' : from_backend['CP choose care later payment']
          } 
        }, 
        'lump sum' : {
          'choose_legacy' : {
            'old' : from_backend['Legacy LS choose legacy'], 
            'new' : from_backend['CARE LS choose legacy']
          } ,
          'choose_reformed' : {
            'old' : from_backend['Legacy LS choose care'], 
            'new' : from_backend['CARE LS choose care']
          }
        },
        'exact_age' : from_backend['exact age'], 
        'commutation' : inputs.commutation, 
        'commutation1987' : inputs.commutation1987 ? inputs.commutation1987 : "NA",
        'inflation' : inputs.inflation, 
        'retirementAge' : inputs.retirementAge.value, 
        'salaryIncrease' : inputs.salaryIncrease, 
        'equalise' : inputs.equalise, 
        'ERF care': from_backend['ERF care'],
        'ERF legacy': from_backend['ERF care'],
        'ERF legacy ls': from_backend['ERF care'],
        'reformed_npa' : from_backend['reformed npa'], 
        'legacy_npa' : from_backend['legacy npa'],
        'unauthorised_payment_charge' : {
          'choose_legacy' : from_backend["unauthorised payment charge choose legacy"],
          'choose_care' : from_backend["unauthorised payment charge choose care"]
        }
      }
      return ret
    })
    .catch(error => {
      console.error('Error occurred:', error);
      throw error;  // Rethrow the error to be handled by the caller
    });
};