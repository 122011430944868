import React, { useState, useContext, useRef, useEffect } from "react";
import InfoModal from "../../general/InfoModal";
import { InputDataContext } from "../../../shared/contexts/InputDataContext";
import "react-datepicker/dist/react-datepicker.css";
import FullDateDropdown from './FullDateDropdown'
import MultipleChoice from "./MultipleChoice";
import PrettyTextInput from './PrettyTextInput'
import ServiceInput from "./ServiceInput";
import GenericDropdown from "./GenericDropdown"

import classes from "./Inputs.module.css";
import { poundToNumber } from "../../../utilities/Formatters";

const Input = (props) => {
  
  const { scrollPositions} = useContext(InputDataContext);
  const inputs = props.inputs
  const setInputs = props.setInputs
  const valid = props.valid
  const setValid = props.setValid
  const need_currency = props.input === 'salary2015' | props.input === 'salaryCurrent'| props.input === 'transferredService2015'

  const errorTimeoutRef = useRef(null)
  const validTimeoutRef = useRef(null)

  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (error)  { setShowError(true); }
    if (!error) { setShowError(false); }
  }, [error]);


  function debounce(func, wait, name_of_fun, timeoutRef) {
    // Enhanced function to allow cancelation
    const debouncedFunction = function(...args) {
      const later = () => {
        clearTimeout(timeoutRef.current);
        func.apply(this, args);
      };
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(later, wait);
    };
    return debouncedFunction;
  }

  const debouncedSetError = debounce(setError, 150, 'setError', errorTimeoutRef)
  const debouncedSetValid = debounce(setValid, 150, 'setValid', validTimeoutRef)
  
  const changeHandler = (event) => {

    var val = need_currency ? poundToNumber(event.target.value) : event.target.value
    //var val = event.target.value

    if(props.input === 'retirementAge') {
      var val = String(val).slice(0,2)
    } 

    setInputs({ ...inputs, [props.input]: val });

    if(['inReceipt', 'hasLeft'].includes(props.input)) {
      if(val === 'Yes') {
        debouncedSetValid((prev) => {return({...prev, [props.input] : false})})
        return;
      }
    }

    if (props.inputType === "number" | props.inputType === "pretty") {
     
      var userInput = Number(val)
      
      let minValue = props.minValue;
      let maxValue = props.maxValue;

      if (userInput < minValue) {
        debouncedSetError(props.minValErrorMsg);
        debouncedSetValid({ ...valid, [props.input]: false });
        return;
      }
      
      if (userInput > maxValue) {
        debouncedSetError(props.maxValErrorMsg);
        debouncedSetValid({ ...valid, [props.input]: false });
        return;
      }
    }

    if (props.input === 'dateOfJoining') {
      const dateOfJoining = new Date(event.target.value);
      const minDate = new Date(props.minValue);
      const maxDate = new Date(props.maxValue);

      if (dateOfJoining < minDate) {
        debouncedSetError(props.minValErrorMsg);
        debouncedSetValid({ ...valid, [props.input]: false });
        return;
      }

      if (dateOfJoining > maxDate) {
        debouncedSetError(props.maxValErrorMsg);
        debouncedSetValid({ ...valid, [props.input]: false });
        return;
      }

      if (dateOfJoining >= new Date('2006-04-06') && inputs.legacyScheme !== '2006') {
        debouncedSetError(props.invalidDateErrorMsg2006);
        debouncedSetValid({ ...valid, [props.input]: false });
        return;
      }

      if (dateOfJoining < new Date('2006-04-06') && inputs.legacyScheme !== '1987') {
        debouncedSetError(props.invalidDateErrorMsg1987);
        debouncedSetValid({ ...valid, [props.input]: false });
        return;
      }
      
      debouncedSetError(null);
      debouncedSetValid({ ...valid, [props.input]: true });
    } 
    else if (props.input === "dateOfTransfer") {
      const dateOfTransfer = new Date(event.target.value);
      const dateOfJoining = new Date(inputs.dateOfJoining)
      let dateMinValue = new Date(props.minValue);
      let dateMaxValue = new Date(props.maxValue);
      let remedyPeriodStart = new Date('2015-04-01');
      let remedyPeriodEnd = new Date('2022-04-01');

      if ((dateOfTransfer < dateMinValue) | (dateOfTransfer < dateOfJoining)) {
        debouncedSetError(props.minValErrorMsg);
        debouncedSetValid({ ...valid, [props.input]: false });
        return;
      }

      if (dateOfTransfer > dateMaxValue) {
        debouncedSetError(props.maxValErrorMsg);
        debouncedSetValid({ ...valid, [props.input]: false });
        return;
      }
      
      //have set this to valid as this is a warning message rather than an error which stops them from continuing
      if (dateOfTransfer >= remedyPeriodStart & dateOfTransfer < remedyPeriodEnd) {
        debouncedSetError(props.invalidTransferPeriodMsg);
        debouncedSetValid({ ...valid, [props.input]: true });
        return;
      }
    }
    else if (props.inputType === "date") {
      let userInput = new Date(event.target.value);
      let dateMinValue = new Date(props.minValue);
      let dateMaxValue = new Date(props.maxValue);

      if (userInput < dateMinValue) {
        debouncedSetError(props.minValErrorMsg);
        debouncedSetValid({ ...valid, [props.input]: false });
        return;
      }

      if (userInput > dateMaxValue) {
        debouncedSetError(props.maxValErrorMsg);
        debouncedSetValid({ ...valid, [props.input]: false });
        return;
      }
    } 
    
    clearTimeout(validTimeoutRef.current)
    setValid({ ...valid, [props.input]: true });
    clearTimeout(errorTimeoutRef.current)
    setError(null);
  };

  const dropdownChangeHandler = (selectedItem) => {
    setInputs(prev => ({ ...prev, [props.input]: selectedItem }));
  
    if (selectedItem.value) {
      debouncedSetError(null);
      debouncedSetValid({ ...valid, [props.input]: true });
    } else {
      debouncedSetError("Please select an option");
      debouncedSetValid({ ...valid, [props.input]: false });
    }
  };

  useEffect(() => {
    if (props.input === "dateOfJoining") {
      // re-validating date of joining if legacy scheme or date of birth change after it is first populated
      if (!inputs.dateOfJoining) return;
      
      const dateOfJoining = new Date(inputs.dateOfJoining);
      const minDate = new Date(props.minValue);
      const maxDate = new Date(props.maxValue);

      if (dateOfJoining < minDate) {
        debouncedSetError(props.minValErrorMsg);
        debouncedSetValid((prev) => ({ ...prev, [props.input]: false })); 
        return;
      }

      if (dateOfJoining > maxDate) {
        debouncedSetError(props.maxValErrorMsg);
        debouncedSetValid((prev) => ({ ...prev, [props.input]: false })); 
        return;
      }
  
      if (dateOfJoining >= new Date('2006-04-06') && inputs.legacyScheme !== '2006') {
        debouncedSetError(props.invalidDateErrorMsg2006);
        debouncedSetValid((prev) => ({ ...prev, [props.input]: false })); 
        return;
      }

      if (dateOfJoining < new Date('2006-04-06') && inputs.legacyScheme !== '1987') {
        debouncedSetError(props.invalidDateErrorMsg1987);
        debouncedSetValid((prev) => ({ ...prev, [props.input]: false })); 
        return;
      }

      debouncedSetError(null);
      debouncedSetValid((prev) => ({ ...prev, [props.input]: true })); 
    }
  }, [inputs.legacyScheme, inputs.dateOfBirth]);

  useEffect(() => {
    // ensuring that transfer date is still valid after date of joining if date of joining changes 
    // this also runs on initial mount to ensure that warning still appears when navigating back
    if (props.input === "dateOfTransfer") {
      if (!inputs.dateOfTransfer) return;
      const dateOfTransfer = new Date(inputs.dateOfTransfer);
      const dateOfJoining = new Date(inputs.dateOfJoining)
      let dateMinValue = new Date(props.minValue);
      let dateMaxValue = new Date(props.maxValue);
      let remedyPeriodStart = new Date('2015-04-01');
      let remedyPeriodEnd = new Date('2022-04-01');
      
      if ((dateOfTransfer < dateMinValue) | (dateOfTransfer < dateOfJoining)) {
        debouncedSetError(props.minValErrorMsg);
        debouncedSetValid((prev) => ({ ...prev, [props.input]: false })); 
        return;
      }

      if (dateOfTransfer > dateMaxValue) {
        debouncedSetError(props.maxValErrorMsg);
        debouncedSetValid((prev) => ({ ...prev, [props.input]: false })); 
        return;
      }

      //have set this to valid as this is a warning message rather than an error which stops them from continuing
      if (dateOfTransfer >= remedyPeriodStart & dateOfTransfer < remedyPeriodEnd) {
        debouncedSetError(props.invalidTransferPeriodMsg);
        debouncedSetValid((prev) => ({ ...prev, [props.input]: true })); 
        return;
      }

      debouncedSetError(null);
      debouncedSetValid((prev) => ({ ...prev, [props.input]: true })); 
    }
  }, [inputs.dateOfJoining])
  
  const validateTransferredServiceAge = (dateOfTransfer, yearsOfService, daysOfService, dateOfBirth) => {
    // this function is checking the implied date of joining based on the user's transferred service inputs and transfer date
    // it should flag if the inputs imply that the user joined younger than 16
    if (!isNaN(yearsOfService) && !isNaN(daysOfService)) {
      const impliedDateOfJoining = new Date(dateOfTransfer);
      impliedDateOfJoining.setFullYear(impliedDateOfJoining.getFullYear() - yearsOfService);
      impliedDateOfJoining.setDate(impliedDateOfJoining.getDate() - daysOfService);
  
      const minimumDateOfJoining = new Date(dateOfBirth);
      minimumDateOfJoining.setFullYear(minimumDateOfJoining.getFullYear() + 16);
  
      if (impliedDateOfJoining < minimumDateOfJoining) {
        return 'Your service implies that you joined the scheme younger than age 16.';
      }
    }
    return null; // No error
  };

  const infoMessage = props.help;

  return (

    <div className={classes.input_container + (props.invisible ?  (' ' + classes.invisible) : '' )} style = {{'--box-width' : '100%', '--left-margin' : '5px'}}>
      {showModal && (<InfoModal modalHandler={modalHandler} infoMessage={infoMessage} />)}
      <section>
        <p>{props.text}</p>
        {infoMessage && <button className = {classes.modal_button} onClick={modalHandler}>Help</button>}
      </section>
      
      {props.inputType === "select" && (
        <MultipleChoice
          options = {props.options}
          changeHandler = {changeHandler}
          chosen = {(inputs[props.input] === undefined ) ? '' : inputs[props.input]}
          input = {props.input}
        />
      )}
      
      {props.inputType === "date" && (
        <FullDateDropdown
          existing_value = {(inputs[props.input] === undefined ) ? '' : inputs[props.input]}
          onChange = {changeHandler}
          scrollPositions = {scrollPositions[props.input]}
          min_date = {props.min_date}
          min_year = {props.min_year}
          max_year = {props.max_year}
          go_red = {showError && error}
        />
      )}

      {props.inputType === "number" && (
        <input
          value ={(inputs[props.input] === undefined) ? '' : inputs[props.input]}
          type="number"
          onChange={changeHandler}
          min={(isNaN(props.minValue) | (props.minValue === undefined)) ? '' : props.minValue}
          step={1}
          max={props.maxValue === NaN ? '' : props.maxValue}
        />
      )}

      {props.inputType === "dropdown" && (  // New condition for dropdown select
        <GenericDropdown
          options={props.options}
          value={inputs[props.input] || ''}
          setValue={(val) => setInputs((prevInputs) => ({ ...prevInputs, [props.input]: val, }))}         
          label={props.label}
          onChange = {dropdownChangeHandler}
          scrollPositions={scrollPositions[props.input]}
          go_red={showError && error}
          allowCustomAmount={props.allowCustomAmount}
          allowNoValue={!props.allowNoValue ? false : true}
        />
      )}

      {props.inputType === "pretty" && (
        <PrettyTextInput
          type = {need_currency ? 'currency' : 'number'}
          label = {props.label}
          value ={(inputs[props.input] === undefined) ? '' : inputs[props.input]}
          onChange = {changeHandler}
          showError = {showError}
          error = {error}
        />
      )}

      {props.inputType === 'service' && (
        <ServiceInput
          onChangeYear = {
            (event) => {
              props.setInputs({...props.inputs, 'transferredServiceLegacyYears' : Number(event.target.value)})
              let userInput = Number(event.target.value);
              let minValue = props.minValue;
              let maxValue = props.maxValue;
        
              if (userInput <= minValue && props.inputs.transferredServiceLegacyDays === 0) {
                debouncedSetError(props.minValErrorMsg);
                debouncedSetValid({ ...valid, 'transferredServiceLegacy': false });
                return;
              }
        
              if (userInput > maxValue) {
                debouncedSetError(props.maxValErrorMsg);
                debouncedSetValid({ ...valid, 'transferredServiceLegacy': false });
                return;
              }
              
              const error = validateTransferredServiceAge(
                inputs.dateOfTransfer,
                userInput,
                props.inputs.transferredServiceLegacyDays,
                inputs.dateOfBirth
              );

              if (error) {
                setError(error);
                setValid({ ...valid, 'transferredServiceLegacy': false });
              } else {
                clearTimeout(errorTimeoutRef.current)
                setError(null)
                clearTimeout(validTimeoutRef.current)
                setValid({...valid, 'transferredServiceLegacy' : true})
              }
            }
          }
          onChangeDay = {
            (event) => {
              var val = Number(event.target.value)
              val = Math.max(val, 0)
              val = Math.min(val, 365)
              props.setInputs({...props.inputs, 'transferredServiceLegacyDays' : val})

              const error = validateTransferredServiceAge(
                inputs.dateOfTransfer,
                props.inputs.transferredServiceLegacyYears,
                val,
                inputs.dateOfBirth
              );

              if (error) {
                setError(error);
                setValid({ ...valid, 'transferredServiceLegacy': false });
              } else {
                setError(null);
                setValid({ ...valid, 'transferredServiceLegacy': true });
              }
            }
          }
          years = {props.inputs.transferredServiceLegacyYears}
          days = {props.inputs.transferredServiceLegacyDays}
          error = {error}
        />
      )}

      <p className={classes.ErrorMsg + ((showError && error) ? '' : (' ' + classes.invisible))}>{error}</p>

    </div>

  );
};

export default Input;
